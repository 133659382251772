




























import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { IOption } from "@/models/interface-v2/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoicePrepayment } from "@/services-v2/invoice-prepayment.service";
import { invoiceServices } from "@/services-v2/invoice.service";
import Vue from "vue";
export default Vue.extend({
  name: "CSelectInvoice",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    receiptArForm: {
      type: Object as () => {
        branchWarehouseId: string,
        currencyId: string,
        customerId: string,
        invoiceType: "Invoice AR" | "Credit Memo" | "Prepayment",
      },
      default: undefined,
    }
  },
  data() {
    this.getList = debounceProcess(this.getList, 300);
    return {
      dtOpt: [] as IOption[],
      loading: false,
    };
  },
  computed: {},
  watch: {
    "receiptArForm.invoiceType"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    "receiptArForm.currencyId"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    "receiptArForm.customerId"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    "receiptArForm.branchWarehouseId"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
  },
  
  created(): void {
    this.getList();
  },
  methods: {
    onSelectInvoiceNumber(value, meta): void {
      this.$emit("input", value);
      this.$emit("on-select", { value, meta });
    },
    getList(search = ""): void {
      if (this.receiptArForm.invoiceType === "Invoice AR") {
        this.getInvoiceARList(search);
        this.getInvoiceCreditMemoList(search);
      } else if (this.receiptArForm.invoiceType === "Credit Memo") {
        this.getInvoiceCreditMemoList(search);
      } else if (this.receiptArForm.invoiceType === "Prepayment") {
        this.getInvoicePrepaymentList(search);
      }
    },
    async getInvoiceARList(search = ""): Promise<void> {
      try {
        this.loading = true;
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 20,
          search: `status~PARTIAL_PAID_OR_status~UNPAID_AND_branchWarehouse.secureId~${this.receiptArForm.branchWarehouseId}`,
        };
        if (search) {
          param.search += `_AND_documentNumber~*${search}*`;
        }
  
        if (this.receiptArForm.currencyId) {
          param.search += `_AND_priceCurrency.secureId~${this.receiptArForm.currencyId}`;
        }
  
        if (this.receiptArForm.customerId) {
          param.search += `_AND_customer.secureId~${this.receiptArForm.customerId}`;
        }
  
        const res = await invoiceServices.getListInvoiceAR(param);
        this.dtOpt = res.data.map(x => ({key: x.documentNumber, value: x.documentNumber, meta: x}));
      
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async getInvoiceCreditMemoList(docNumber?: string): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 20,
          search: `branch.secureId~${this.receiptArForm.branchWarehouseId}_AND_remainingAmount>0_AND_invoicePrepaymentStatus~UN_APPLIED_AND_invoiceType~${trimSpaceToUnderscore(this.receiptArForm.invoiceType)}`,
        };
        if (docNumber) {
          param.search += `_AND_invoicePrepaymentNumber~*${docNumber}*`;
        }

        if (this.receiptArForm.customerId) {
          param.search += `_AND_customer.secureId~${this.receiptArForm.customerId}`;
        }
        
        const res = await invoicePrepayment.getListInvoicePrepayment(param);
        if(this.receiptArForm.invoiceType === "Credit Memo") {
          // findColumn[0].options = res.data;
          this.dtOpt = res.data.map(x => ({ key: x.invoiceNumber || "", value: x.invoiceNumber || "", meta: x }));
        }

        // buat option credit memo
        // this.dtOpt.creditMemo = res.data.map(x => ({ key: x.invoiceNumber, value: x.id, meta: x }));
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async getInvoicePrepaymentList(docNumber?: string): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 20,
          search: `branch.secureId~*${this.receiptArForm.branchWarehouseId}*_AND_invoicePrepaymentStatus~UNPAID`,
        };
        if (docNumber) {
          param.search += `_AND_invoicePrepaymentNumber~*${docNumber}*`;
        }
        
        if (this.receiptArForm.currencyId) {
          param.search += `_AND_currency.secureId~${this.receiptArForm.currencyId}`;
        }
        
        if (this.receiptArForm.customerId) {
          param.search += `_AND_customer.secureId~${this.receiptArForm.customerId}`;
        }
    
        const res = await invoicePrepayment.getListInvoicePrepayment(param);
        if(this.receiptArForm.invoiceType === "Prepayment") {
          this.dtOpt = res.data.map(x => ({key: x.invoicePrepaymentNumber, value: x.id, meta: x}));
        }
        // findColumn[1].options = res.data
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    }
  },
});
