import { AxiosError } from "axios";
import { HttpClient } from "@/services/http.service";
// Class
import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  DataResponseGetListInvoiceRecurring,
  RequestGenerateInvoiceRecurring,
  DataResponseGenerateInvoiceRecurring,
  ResponseListArInvoice,
  PostInvoice,
  ResponsePostInvoice,
  ResponseDetailInvoiceAR,
  CancelRejectInvoice,
  DetailRecurring,
  ResponseReportInvoiceControlling,
  GenerateReceipt,
  ApproveInvoice,
  ResponseListJoinInvoiceNumber,
  ResponseListJoinInvoiceAndPrepayment,
  RequestPayloadJoinInvoice,
  ResponseJoinInvoiceDetail,
  RequestSubmitQC,
  ParamDownloadListInvoice,
  RequestParamsListInvoiceBySo,
} from "@interface/invoice.interface";
import { IGenericResponsePost } from "@/models/interface-v2/common.interface";

export class InvoiceServices extends HttpClient {
  getListJoinInvoiceNumber = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListJoinInvoiceNumber> => {
    return this.get<ResponseListJoinInvoiceNumber>(Api.JoinInvoice, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailJoinInvoiceNumber = (
    id: string
  ): Promise<ResponseJoinInvoiceDetail> => {
    return this.get<ResponseJoinInvoiceDetail>(Api.JoinInvoice + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListJoinInvoiceAndPrepayment = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListJoinInvoiceAndPrepayment> => {
    return this.get<ResponseListJoinInvoiceAndPrepayment>(
      Api.JoinInvoiceAndPrepayment,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInvoiceAR = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListArInvoice> => {
    return this.get<ResponseListArInvoice>(Api.InvoiceAR, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailInvoiceAR = (id: string): Promise<ResponseDetailInvoiceAR> => {
    return this.get<ResponseDetailInvoiceAR>(Api.InvoiceAR + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailInvoiceRecurring = (id: string): Promise<DetailRecurring> => {
    return this.get<DetailRecurring>(Api.InvoiceRecurring + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInvoiceRecurringBap = (
    params: RequestQueryParamsModel
  ): Promise<DataResponseGetListInvoiceRecurring[]> => {
    return this.get<DataResponseGetListInvoiceRecurring[]>(
      Api.InvoiceRecurring,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  generateInvoiceRecurringBap = (
    payload: RequestGenerateInvoiceRecurring
  ): Promise<DataResponseGenerateInvoiceRecurring> => {
    return this.post<
      DataResponseGenerateInvoiceRecurring,
      RequestGenerateInvoiceRecurring
    >(Api.InvoiceRecurring + "/generate", payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createJoinInvoice = (
    payload: RequestPayloadJoinInvoice
  ): Promise<ResponseJoinInvoiceDetail> => {
    return this.post<ResponseJoinInvoiceDetail, RequestPayloadJoinInvoice>(
      Api.JoinInvoice,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createInvoice = (payload: PostInvoice): Promise<ResponsePostInvoice> => {
    return this.post<ResponsePostInvoice, PostInvoice>(Api.InvoiceAR, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  generateReceipt = (data: string): Promise<GenerateReceipt> => {
    return this.post<any, GenerateReceipt>(Api.GenerateArReceipt + `/${data}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printInvoice = (id: string): Promise<Blob> => {
    return this.get<Blob>(Api.InvoiceAR + `/print/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printTaxInvoice = (params: RequestQueryParamsModel): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.ReportTaxInvoice, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getInvoiceCode = (
    id: string,
    params: RequestQueryParamsModel
  ): Promise<any> => {
    return this.get<any>(Api.InvoiceARCode + "/" + id, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printReceipt = (
    payload: GenerateReceipt,
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.post<any, Blob>(Api.PrintArReceipt, payload, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  approveInvoice = (
    payload: ApproveInvoice[],
    id: string
  ): Promise<ResponseDetailInvoiceAR> => {
    return this.post<ResponseDetailInvoiceAR, ApproveInvoice>(
      Api.InvoiceARApprove + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  updateInvoice = (
    payload: PostInvoice,
    id: string
  ): Promise<ResponsePostInvoice> => {
    return this.put<any, ResponsePostInvoice>(Api.InvoiceAR + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  submitInvoice = (
    payload: PostInvoice,
    id: string
  ): Promise<ResponsePostInvoice> => {
    return this.post<any, ResponsePostInvoice>(
      Api.InvoiceARSubmit + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelInvoice = (id: string): Promise<boolean> => {
    return this.delete<boolean>(Api.InvoiceARCancel + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  rejectInvoice = (
    payload: CancelRejectInvoice,
    id: string
  ): Promise<ResponsePostInvoice> => {
    return this.post<any, ResponsePostInvoice>(
      Api.InvoiceARReject + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelJoinInvoice = (id: string): Promise<boolean> => {
    return this.post<boolean, string>(Api.JoinInvoice + `/cancel/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteJoinInvoice = (id: string): Promise<boolean> => {
    return this.delete<boolean>(Api.JoinInvoice + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   *
   * @param id invoice id
   * @param payload QC Form
   * @returns
   */
  submitQcInvoice = (
    id: string,
    payload: RequestSubmitQC[]
  ): Promise<IGenericResponsePost> => {
    return this.put<IGenericResponsePost, RequestSubmitQC[]>(
      `${Api.InvoiceAR}/qc-submit/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * approve invoice
   * @param id invoice id
   * @returns
   */
  approveQcInvoice = (id: string): Promise<IGenericResponsePost> => {
    return this.put<IGenericResponsePost, { id: string }>(
      `${Api.InvoiceAR}/qc-approve/${id}`,
      null
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadList = (params: ParamDownloadListInvoice): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(`${Api.InvoiceAR}/excel/download`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInvoiceArBySo = (
    params: RequestParamsListInvoiceBySo
  ): Promise<ResponseListArInvoice> => {
    return this.get<ResponseListArInvoice>(Api.InvoiceAR + "/so", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}
export const invoiceServices = new InvoiceServices();
