import {AxiosError} from "axios";
import {HttpClient} from "@/services/http.service";
// Class
import {Api} from "@/models/class/api.class";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { CreateInvoicePrepayment, InvoicePrepayment, ResponseCreateInvoicePrepayment } from "@interface/invoice-prepayment.interface";

export class InvoicePrepaymentServices extends HttpClient {
    getListInvoicePrepayment = (params: RequestQueryParamsModel): Promise<InvoicePrepayment> => {
        return this.get<InvoicePrepayment>(Api.InvoicePrepayment, {params})
            .then(this.success)
            .catch((error: AxiosError<ErrorResponseModel>) => {
                throw this.error(error);
            });
    }
    createInvoicePrepayment = (payload: CreateInvoicePrepayment): Promise<ResponseCreateInvoicePrepayment> => {
        return this.post<any,ResponseCreateInvoicePrepayment>(Api.InvoicePrepayment, payload)
            .then(this.success)
            .catch((error: AxiosError<ErrorResponseModel>) => {
                throw this.error(error);
            });
    }
    cancelInvoicePrepayment = (id:string): Promise<ResponseCreateInvoicePrepayment> => {
        return this.put<any,ResponseCreateInvoicePrepayment>(Api.CancelInvoicePrepayment+ "/" + id)
            .then(this.success)
            .catch((error: AxiosError<ErrorResponseModel>) => {
                throw this.error(error);
            });
    }
    detailInvoicePrepayment = (id:string): Promise<ResponseCreateInvoicePrepayment> => {
        return this.get<ResponseCreateInvoicePrepayment>(Api.InvoicePrepayment + "/" + id)
            .then(this.success)
            .catch((error: AxiosError<ErrorResponseModel>) => {
                throw this.error(error);
            });
    }
    getInvoicePrepaymentCode = (id:string): Promise<any> => {
        return this.get<any>(Api.InvoicePrepaymentCode + "/" + id)
            .then(this.success)
            .catch((error: AxiosError<ErrorResponseModel>) => {
                throw this.error(error);
            });
    }
    printInvoicePrepayment = (id:string): Promise<ArrayBuffer> => {
        return this.get<ArrayBuffer>(Api.InvoicePrepayment+`/print/${id}`, {responseType: "arraybuffer"})
            .then(this.success)
            .catch((error: AxiosError<ErrorResponseModel>) => {
                throw this.error(error);
            });
      }
}
export const invoicePrepayment = new InvoicePrepaymentServices();